<template>
  <div>
    <div class="accordion mb-2" role="tablist">
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button
            block
            @click="active = !active"
            style="background:#e5e9f0; color:black !important; border:none !important;"
          >
            <div class="row">
              <div class="col text-left mt-1">
                <i class="mdi mdi-format-list-bulleted"></i>
                {{ roomType }}
                
              </div>
              <div class="col text-right">
               <span v-if="canAny(['confirm_host_ods','validate_host_ods'])">Total: {{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD' }).format(service[roomType][0].subTotal ? service[roomType][0].subTotal :  0)}}</span> 
                <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i>
                <i v-else class="mdi mdi-chevron-down font-size-18"></i>
              </div>
            </div>
          </b-button>
        </b-card-header>
        <b-collapse
          :id="`accordion-${pos}`"
          :visible="active"
          accordion="my-accordion"
          role="tabpanel"
        >
          <b-card-body class="shadow-lg">
            <fieldset :disabled="!disable">
              <div class="mt-3">
                <div class="row">
                  <div class="col-lg-3">
                    <label for="">Consommation Période (Système)</label>
                    <input
                      type="text"
                      class="form-control bg-light"
                      :value="countTotalBookingNights(service[roomType][0].room_type)"
                      readonly
                    />
                  </div>

                  <div class="col-lg-3">
                    <label for="">Consommation Période (Réelle)</label>
                    <input
                      type="number"
                      v-model="service[roomType][0].realQty"
                      class="form-control"
                      @input="getTotalAmnt(service[roomType][0])"
                    />
                  </div>
                </div>
              </div>
            </fieldset>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>

export default {
    props: {
      service: null,
      roomType: null,
      ODS: null,
      send: null,
      pos: null,
      estimated: null,
      disable: null,
    },
    data() {
      return {
        active:false,
        roomsFinalData: {},
        total_month:0,
      }
    },
    watch: {
      // service(){
      //   if(this.service){
      //     this.roomsFinalData = this.service[this.roomType]
      //     this.roomsFinalData[0].subTotal = this.service[this.roomType][0].amount;
      //     this.roomsFinalData[0].realQty = this.service[this.roomType][0].quantity;
      //     this.countTotalBookingNights(this.roomsFinalData[0].room_type)

      //     this._emitData();
      //   }
      // }
    },

    mounted(){
          this.roomsFinalData = this.service[this.roomType]
          this.roomsFinalData[0].subTotal = this.service[this.roomType][0].amount;
          this.roomsFinalData[0].realQty = this.service[this.roomType][0].quantity;
          this.countTotalBookingNights(this.roomsFinalData[0].room_type)

          this._emitData();
    },

    methods: {
      countTotalBookingNights(roomTypeData){
          var total = 0;
          if(roomTypeData.bookings != undefined){
            roomTypeData.bookings.forEach(element => {
                total += parseInt(element.nights);
            });
          }else{
            total = roomTypeData.quantity;
            roomTypeData.realQty = total;
          }
          this.total_month = total;
          return total;
      },

      getTotalAmnt(roomTypeData){
        var total = 0;
        var qty = roomTypeData.realQty;
        var unitPrice = 0;

        if(roomTypeData.unit_price != undefined) unitPrice = roomTypeData.unit_price
        else if(roomTypeData.prestation.unit_price != undefined) unitPrice = roomTypeData.prestation.unit_price
        else unitPrice = 0;
        
        console.log("unitPrice => "+unitPrice);
        total = parseInt(unitPrice) * parseInt(qty);
        console.log("qty => "+qty);
        if(isNaN(total)){
          total = 0;
        }
        roomTypeData.subTotal = total;
        // console.log(this.service[this.roomType][0].subTotal);
        this._emitData();

        return total ? total : 0;
        // return  new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD' }).format(total ? total : 0);
      },

      _emitData(){
        this.$emit('getvalues',this.service[this.roomType]);
        this.$emit('getvaluesEmitted',true);
      }
    },

};
</script>
